import {Typography, Button} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {AllPilotsData, jediAllPilotsQuery} from "../../clients/graphql/components/tables-queries";

export const AllPilotsTable = () => {

    const {loading, error, data} = useQueryCoordinate(jediAllPilotsQuery, {});
    const pilots = data?.jediAllPilots?.pilots;

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns = [
        {
            field: 'title',
            headerName: 'Pilot',
            width: 250,
        },
        {
            field: 'userKey',
            headerName: 'User Key',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Button href={`/users/${params.value}`}>
                        {params.value}
                    </Button>
                )
            }
        },
        {
            field: 'portfolioKey',
            headerName: 'Portfolio Key',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Button href={`/portfolios/${params.value}`}>
                        {params.value}
                    </Button>
                )
            }
        },
        {
            field: 'tradeDelayTime',
            headerName: 'Trade Delay',
            width: 100,
        },

        {
            field: 'currentYear',
            headerName: 'Current Year',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value == null) {
                    return ''; // You can also use '0%' or any other placeholder text.
                }

                const value = params.value * 100;

                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                        <Typography fontSize={'small'} style={{ color: value >= 0 ? 'green' : 'red' }}>
                            {value.toFixed(2)}%
                        </Typography></div>
                )
            }
        },
        {
            field: 'previousYear',
            headerName: 'Previous Year',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value == null) {
                    return ''; // You can also use '0%' or any other placeholder text.
                }

                const value = params.value * 100;

                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                        <Typography fontSize={'small'} style={{ color: value >= 0 ? 'green' : 'red' }}>
                            {value.toFixed(2)}%
                        </Typography></div>
                )
            }
        },
    ];

    const rows = pilots.map((row: AllPilotsData, index: number) => {
        return {
            id: index,
            ...row,
        }
    });

    return (
        <>
            <Typography variant="subtitle1" component="div" align="left">PILOTS</Typography>
            <div style={{height: 700, width: '100%'}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    rowHeight={40}
                    hideFooter={true}
                />
            </div>
        </>
    );
}