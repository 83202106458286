import React from "react";
import {useParams} from "react-router-dom";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {
    Alert,
    CircularProgress,
    Grid,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import PortfolioEventCrudPanel from "../../views/portfolios/PortfolioEventCrudPanel";
import PortfolioEventPilotDetails from "../../views/portfolios/PortfolioEventPilotDetails";
import PortfolioEventBrokerACHDetails from "../../views/portfolios/PortfolioEventBrokerACHDetails";
import {PortfolioEventDetailsGet} from "../../clients/graphql/pages/users-queries";


export default function PortfolioEventPage () {

    const {eventKey} = useParams() || '';

    const {loading, error, data} = useQueryCoordinate(PortfolioEventDetailsGet, {variables: { portfolioEventKey : parseInt(eventKey as string)}});

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'PortfolioEventDetailsGet'</Alert>;
    }

    return (
        <Box>
            <Typography variant="h4">Portfolio Event Key: {eventKey}</Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PortfolioEventCrudPanel eventKey={eventKey as string} data={data?.portfolioEventDetailsGet?.portfolioEvent} />
                </Grid>
                <Grid item xs={4}>
                    <PortfolioEventPilotDetails data={data?.portfolioEventDetailsGet?.pilotDetails} />
                    <PortfolioEventBrokerACHDetails data={data?.portfolioEventDetailsGet?.brokerACHDetails} />
                </Grid>
            </Grid>

        </Box>
    )
}