import {gql} from "@apollo/client";

export const portfolioEventsGet = gql`
    query portfolioEventsGet (
        $cursor: Int!){
            portfolioEventsGet( input: {cursor: $cursor} ) {
                portfolioEvents {
                    portfolioKey
                    portfolioEventKey
                    status
                    type
                    portfolioKey
                    amount
                    createdAt
                    reason
                    autoPilotSettingsKey
                    brokerName
                    userKey
                }
                nextCursor
            }
    }`;

export interface PortfolioEventGeneric {
    portfolioKey: string;
    portfolioEventKey: string;
    status: string;
    type: string;
    amount: number;
    createdAt: string;
    reason: string;
    autoPilotSettingsKey: string;
    brokerName: string;
    userKey: string;
}