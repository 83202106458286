import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {ActivePilotsQuery, RecurringInvestmentsQuery} from "../../clients/graphql/components/buttons-queries";
import {SlaveAutoPilotPortfolio,} from "../../clients/graphql/pages/users-queries";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";

const capitalizeFirstLetter = (text: string): string => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const formatFrequency = (text: string): string => {
    const lowered = capitalizeFirstLetter(text)
    if (lowered === 'Day'){
        return 'Daily'
    }
    else{
        return lowered + 'ly'
    }
};

interface RecurringInvestmentsButtonProps {
    userKey: string;
}

interface InvestmentsListProps {
    pilots: SlaveAutoPilotPortfolio[];
    userKey: string;
    autoPilotSettingsKeys: number[];
}

interface autoPilotRecurringInvestmentDataProps {
    amount: number;
    autoPilotSettingsKey: number;
    brokerName: string;
    createdAt: Date;
    interval: string;
    intervalCount: string;
    lastDepositAt: Date;
    nextDepositAt: Date;
    portfolioName: string;
    startAt: Date;
    status: string;
}

interface combinedDataProps {
    createdAt: Date;
    amount: number;
    nextDepositAt: Date;
    lastDepositAt: Date;
    autoPilotSettingsKey: number;
    intervalCount: string;
    interval: string;
    brokerName: string;
    portfolioName: string;
    pilotDetails: SlaveAutoPilotPortfolio | null;
    startAt: Date;
    status: string
}

export function RecurringInvestmentsButton({ userKey }: RecurringInvestmentsButtonProps): JSX.Element {
    const [open, setOpen] = useState(false);

    const {data, loading} = useQueryAsUser(ActivePilotsQuery, {
        userKey: userKey,
    })
    const pilots: SlaveAutoPilotPortfolio[] = data?.slaveAutoPilotPortfoliosGet?.slaveAutoPilotPortfolios;
    const pilotKeys = pilots?.map(item => item.autoPilotSettingsKey)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Button
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                size={'small'}
                color={'primary'}
                onClick={handleOpen}
            >
                Recurring Investments
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Recurring Investments</DialogTitle>
                {loading ? (
                    <CircularProgress size={30} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto", marginBottom: "10px"}} />
                ) : <>
                    <InvestmentsList pilots={pilots} userKey={userKey} autoPilotSettingsKeys={pilotKeys} />
                    </>
                }
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}

const InvestmentsList = ({ pilots, userKey, autoPilotSettingsKeys }: InvestmentsListProps): React.JSX.Element => {
    const { data, loading } = useQueryAsUser(RecurringInvestmentsQuery, {
        variables: {
            autoPilotSettingsKeys: autoPilotSettingsKeys,
        },
        userKey: parseInt(userKey),
    });

    const recurringPilots: autoPilotRecurringInvestmentDataProps[] =
        data?.autoPilotRecurringInvestmentDataGet?.autoPilotRecurringInvestmentData;

    const combinedData: combinedDataProps[] = recurringPilots?.map((rcItem) => {
        const matchingPilot = pilots.find(
            (pilot) => pilot.autoPilotSettingsKey === rcItem.autoPilotSettingsKey
        );

        return {
            ...rcItem,
            pilotDetails: matchingPilot || null,
        };
    });

    if (loading) {
        return <CircularProgress size={30} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto", marginBottom: "10px"}} />;
    }

    return (
        <List>
            {combinedData?.map((pilot, index) => (
                <InvestmentSummaryCard key={index} pilot={pilot} />
            ))}
        </List>
    );
};


const InvestmentSummaryCard = ({ pilot }: { pilot: combinedDataProps }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ListItemButton onClick={handleOpen}>
                <ListItemAvatar>
                    <PersonIcon  />
                </ListItemAvatar>

                <ListItemText
                    primary={
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            ${pilot?.amount} • {formatFrequency(pilot?.interval)}
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body2" sx={{ color: "#6D6D6D" }}>
                            {pilot?.pilotDetails?.masterPortfolio?.userFullName}
                        </Typography>
                    }
                />
            </ListItemButton>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>Recurring Investment</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableBody>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{pilot?.status}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amount</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>${pilot?.amount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Pilot</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{pilot?.pilotDetails?.masterPortfolio?.userFullName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Frequency</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{formatFrequency(pilot?.interval)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Brokerage</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{capitalizeFirstLetter(pilot?.brokerName)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Portfolio</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{pilot?.portfolioName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{moment(pilot.startAt).format('MMM D, YYYY')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Next Investment</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 500 }}>{moment(pilot.nextDepositAt).format('MMM D, YYYY')}</TableCell>
                                </TableRow>

                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
