import { gql } from '@apollo/client';

export const GQL_GetWarnings = gql`
  query {
    userAutoPilotWarningsGet {
      warningPrompts {
        title
        description
        type
          priority
          slavePortfolioKey
          actionType
          options {
            route
            bannerColor
            customBannerColor
            bannerIcon
          }
        pastDueSubscriptionIds
      }
    }
  }
`;

export type WhoAmIBasic = {
    userKey: Number,
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    createdAt: Date
}

export type WhoAmIWithSubscriptions = {
    userKey: Number,
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    createdAt: Date,
    zendeskUserId: number,
    subscription: {
        activatedAt: Date,
        products: [{
            name: String,
            prices: [{
                unitAmount: Number,
                displayInterval: String,
            }],
        }],
    }
    metadata: {
        deviceOS:String,
        deviceOSVersion:String,
        appVersionName:String,
    }
}

export const GQL_WhoamiWithSubscriptions = gql`
  query {
    whoami {
      userKey
      firstName
      lastName
      email
      phoneNumber
      createdAt
      zendeskUserId
      subscription {
        activatedAt
        products {
          name
          prices {
            unitAmount
            displayInterval
          }
        }
      }
      metadata{
        deviceOS
        deviceOSVersion
        appVersionName
      }
    }
  }
`;

export const GQL_UserEdit = gql`
  mutation userEdit(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    userEdit(input:{
      firstName: $firstName
      lastName: $lastName
      email: $email
    }) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const GQL_JediUserEdit = gql`
  mutation userEdit(
    $phoneNumber: String!
    $userKey: Int!
  ) {
    jediUserEdit(input:{
      userKey: $userKey
      phoneNumber: $phoneNumber
    }) {
      user {
        phoneNumber
      }
    }
  }
`;