import { gql } from '@apollo/client';

export const jediReenableSlave = gql`
    mutation jediReenableSlave(
        $autoPilotSettingsKey: Int!
    ) {
        jediReenableSlave(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            success
        }
    }
`;

export const jediResolveOverAllocation = gql`
    mutation jediResolveOverAllocation(
        $autoPilotSettingsKey: Int!
    ) {
        jediResolveOverAllocation(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            success
        }
    }
`;

export const balancingDecisionsQuery = gql`
    query balancingDecisionsGet($autoPilotSettingsKey: Int!) {
        balancingDecisionsGet(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            balancingDecisions {
                symbol
                positionType
                orderSide
                reason
                kind
                quantity
                asset {
                    pictureUrl
                }
                price
            }
            balancingParameters
            overAllocatedAmount
        }
    }
`;

export interface BalancingDecision {
    symbol: string;
    positionType: string;
    orderSide: string;
    reason: string;
    kind: string;
    quantity: number;
    asset: {
        pictureUrl: string;
    };
    price: number;
};

export interface BalancingParameters {
    brokerParameters: {
        fundsAvailable: number;
        unsettledFunds: number;
        assetBuyingPowerData: {
            symbol: string;
            buyingPower: number;
            positionType: string;
        }[];
        positionShareSizeData: {
            positionType: string;
            symbol: string;
            minShareSize: number;
        }[];
        ordersToPreventPDTInfraction: any[];
        minFractionalBuyAmount: number;
        mayRoundFractionalCorner: boolean;
        subDollarMinOrderRequirement: number;
        institutionSellWholeFractionOnly: boolean;
        institutionAssetBlacklist: {
            symbol: string;
            assetType: string;
        }[];
    };
    symbolBlacklist: string[];
    positionTypeWhitelist: string[];
    masterPositions: MasterPosition[];
    slavePositions: SlavePosition[];
    brokerPositions: BrokerPosition[];
    settingParameters: SettingParameters;
    masterValuation: number;
    slaveValuation: number;
    valuationRatioOfSlaveToMaster: number;
    minBuyBalanceDecisionAmount: number;
    minSellBalanceDecisionAmount: number;
    inFlightAPOrders: any[];
}

export interface MasterPosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface SlavePosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface BrokerPosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface SettingParameters {
    allocationAmount: number;
    amountPending: number;
    slaveAutopilotPortfolioCash: number;
    masterCashPositionAmount: number;
    maxMasterPositionCount: number;
}