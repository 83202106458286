import {gql} from "@apollo/client";

import { BROKER_NAME, CONNECTION_STATUS } from "../../../shared/portfolio-types";

export const PortfolioEventDetailsGet = gql`
    query ( $portfolioEventKey: Int! ){
        portfolioEventDetailsGet(input: {
            portfolioEventKey: $portfolioEventKey
        }){
            portfolioEvent{
                userKey
                userFullName
                status
                type
                brokerName
                portfolioKey
                amount
                createdAt
                lastPolledAt
                reason
                autoPilotSettingsKey
            }
            pilotDetails{
                masterName
                masterPortfolioKey
            }
            brokerACHDetails{
              amount
              amountOnHold
              status
              earlyAccessAmount
              submittedAt
              settledAt
              createdAt
            }
        }
    }`;

export const jediPortfolioHistoryValueGet = gql`
    query (
        $portfolioKey: Int!
    ){
        jediPortfolioHistoryValueGet(
            input:{
                portfolioKey: $portfolioKey
            }){
                historyValue{
                    marketValue,
                    buyingPower,
                    timestamp,
                    totalValue
                }
        }
    }`;

export const getInstitutionDetails = gql`
    query whoisit($institutionKey: Int!){
        whoisit(
            input: {
                institutionKey: $institutionKey,
            }) {
                  institution {
                    institutionKey
                    userKey
                    createdAt
                    connectionStatus
                    institutionName
                    portfolios{
                      portfolioName
                      portfolioKey
                    }  
                 }
            }
    }`;

export const refreshAuthTokenMutation = gql`
    mutation ($institutionKey: Int!) {
        jediRefreshAuthToken(input:{institutionKey: $institutionKey}){
            success
        }
    }`;

export const disconnectInstitutionMutation = gql`
    mutation ($institutionKey: Int!, $userKey: Int!) {
          JediInstitutionDisconnect(input: {userKey: $userKey, institutionKey: $institutionKey}){
            success
        }
    }`;

export const userPilotDetailsQuery = gql`
    query slaveAutoPilotPortfoliosGet (
        $autoPilotSettingsKey: Int!
    ) {
        slaveAutoPilotPortfoliosGet( input:{
            autoPilotSettingsKey: $autoPilotSettingsKey
            setupStatuses: [DELETED, INITIATED, ACTIVE, PAUSED, SHORT_CIRCUITED]
        }) {
            totalValue
            totalProfitLoss
            totalProfitLossPercent
            slaveAutoPilotPortfolios {
                autoPilotSettingsKey
                masterPortfolio {
                    userFullName
                }
                slaveUserKey
                autoPilotSettings {
                    allocationAmount
                    initiatedAt
                    setupStatus
                    amountPending
                    slavePortfolio {
                        portfolioKey
                        portfolioName
                        brokerName
                        connectionStatus
                    }
                }
                currentPositions {
                    symbol
                    name
                    pictureUrl
                    quantity
                    percentOfPortfolio
                    marketValue
                    currentPrice
                    assetKey
                }
                brokerName
                connectionStatus
                isManualExecution
            }
        }
    }
`;

export const getSlavePortfoliosQuery = gql`
    query getSlavePortfolios  {
        slaveAutoPilotPortfoliosGet( 
            input:{
            setupStatuses: [INITIATED, ACTIVE, SHORT_CIRCUITED]
        }
    ) {
            totalValue
            totalProfitLoss
            totalProfitLossPercent
            slaveAutoPilotPortfolios {
                totalValue
                autoPilotSettingsKey
                masterPortfolio {
                    userFullName
                    portfolioCardImageUrl
                }
                slaveUserKey
                autoPilotSettings {
                    allocationAmount
                    initiatedAt
                    setupStatus
                    amountPending
                    slavePortfolio {
                        portfolioKey
                        portfolioName
                        brokerName
                        connectionStatus
                    }
                }
                pendingTransferData{
                    amount
                    submittedAt
                }
                failedTransferData{
                    amount
                    submittedAt
                }
                currentPositions {
                    symbol
                    name
                    pictureUrl
                    quantity
                    percentOfPortfolio
                    marketValue
                    currentPrice
                    unrealizedProfitLoss
                    unrealizedProfitLossPercent
                    assetDescription
                    assetKey
                }
                autoPilotCashHolding {
                    marketValue
                    assetDescription
                    name
                    percentOfPortfolio
                    symbol
                }
                brokerName
                connectionStatus
                isManualExecution
            }
        }
    }
`;

export interface UserPilotDetailsQueryData {
    totalValue: number;
    totalProfitLoss: number;
    totalProfitLossPercent: number;
    slaveAutoPilotPortfolios: SlaveAutoPilotPortfolio[];
}

export interface SlaveAutoPilotPortfolio {
    slaveUserKey: number;
    autoPilotSettingsKey: number;
    masterPortfolio: {
        userFullName: string;
        portfolioCardImageUrl: string;
    };
    autoPilotSettings: AutoPilotSettings;
    currentPositions: CurrentPosition[];
    failedTransferData: TransferData;
    pendingTransferData: TransferData;
    autoPilotCashHolding: AutoPilotCashHolding;
    brokerName: string;
    connectionStatus: string;
    isManualExecution: boolean;
}

export interface AutoPilotCashHolding{
    marketValue: number;
    symbol: string;
    percentOfPortfolio: number
    assetDescription: string
    name: string
}
export interface TransferData {
    amount: number;
    submittedAt: Date;
}
export interface AutoPilotSettings {
    allocationAmount: number;
    setupStatus: string;
    amountPending: number;
    slavePortfolio: SlavePortfolio;
    initiatedAt: Date;
}

export interface SlavePortfolio {
    portfolioKey: number;
    portfolioName: string;
    brokerName: BROKER_NAME;
    connectionStatus: CONNECTION_STATUS;
}

export interface CurrentPosition {
    symbol: string;
    name: string;
    pictureUrl: string;
    quantity: number;
    percentOfPortfolio: number;
    marketValue: number;
    currentPrice: number;
    assetKey: string;
    unrealizedProfitLossPercent: number
    unrealizedProfitLoss: number
    assetDescription: string
}

export const whoisit = gql`
    query whoisit(
        $portfolioKey: Int
        $userKey: Int
        $autoPilotSettingsKey: Int
        $institutionKey: Int
    ) {
        whoisit(input: {
        portfolioKey: $portfolioKey
        userKey: $userKey
        autoPilotSettingsKey: $autoPilotSettingsKey
        institutionKey: $institutionKey
    }) {
        user {
            userKey
            firstName
            lastName
            deletedAt
        }
        institution {
            institutionKey
        }
    }
  }
`;

export const GQL_GetWarnings = gql`
  query {
    userAutoPilotWarningsGet {
      warningPrompts {
        title
        description
        type
          priority
          slavePortfolioKey
          actionType
          options {
            route
            bannerColor
            customBannerColor
            bannerIcon
          }
        pastDueSubscriptionIds
      }
    }
  }
`;

export type WhoAmIBasic = {
    userKey: Number,
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    createdAt: Date
}

export type WhoAmIWithSubscriptions = {
    userKey: Number,
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    createdAt: Date,
    zendeskUserId: number,
    subscription: {
        activatedAt: Date,
        products: [{
            name: String,
            prices: [{
                unitAmount: Number,
                displayInterval: String,
            }],
        }],
    }
    metadata: {
        deviceOS:String,
        deviceOSVersion:String,
        appVersionName:String,
    }
}

export const GQL_WhoamiWithSubscriptions = gql`
  query {
    whoami {
      userKey
      firstName
      lastName
      email
      phoneNumber
      createdAt
      zendeskUserId
      subscription {
        activatedAt
        products {
          name
          prices {
            unitAmount
            displayInterval
          }
        }
      }
      metadata{
        deviceOS
        deviceOSVersion
        appVersionName
      }
    }
  }
`;

export const GQL_UserEdit = gql`
  mutation userEdit(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    userEdit(input:{
      firstName: $firstName
      lastName: $lastName
      email: $email
    }) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const GQL_JediUserEdit = gql`
  mutation userEdit(
    $phoneNumber: String!
    $userKey: Int!
  ) {
    jediUserEdit(input:{
      userKey: $userKey
      phoneNumber: $phoneNumber
    }) {
      user {
        phoneNumber
      }
    }
  }
`;