import { Button } from "@mui/material";
import { LEGACY_ADMIN_URL } from "../../clients/ClientRegistry";

export type LegacyAdminButtonProps = {
  path: string
}

export function LegacyAdminButton({ path }: LegacyAdminButtonProps) {
  return <Button 
    component="a" 
    href={`${LEGACY_ADMIN_URL}${path}`}
    target="_blank" 
    rel="noopener noreferrer" 
    variant="outlined"
    size={'small'}
    color={'success'}
  >
      View in Legacy
  </Button>;
}