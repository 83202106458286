import { gql } from '@apollo/client';

export const allPerformanceSpansGetQuery = gql`
query allPerformanceSpansGet {
    all: slavePerformanceForSpanGet(
      input: { span: ALL, setupStatuses: [ACTIVE, INITIATED, SHORT_CIRCUITED] }
    ) {
      span
      totalProfitLoss
      totalProfitLossPercent
      slavePerformance {
        autoPilotSettingsKey
        profitLoss
        profitLossPercent
      }
    }
    day: slavePerformanceForSpanGet(
      input: { span: DAY, setupStatuses: [ACTIVE, INITIATED, SHORT_CIRCUITED] }
    ) {
      span
      totalProfitLoss
      totalProfitLossPercent
      slavePerformance {
        autoPilotSettingsKey
        profitLoss
        profitLossPercent
      }
    }
    week: slavePerformanceForSpanGet(
      input: { span: WEEK, setupStatuses: [ACTIVE, INITIATED, SHORT_CIRCUITED] }
    ) {
      span
      totalProfitLoss
      totalProfitLossPercent
      slavePerformance {
        autoPilotSettingsKey
        profitLoss
        profitLossPercent
      }
    }
    month: slavePerformanceForSpanGet(
      input: { span: MONTH, setupStatuses: [ACTIVE, INITIATED, SHORT_CIRCUITED] }
    ) {
      span
      totalProfitLoss
      totalProfitLossPercent
      slavePerformance {
        autoPilotSettingsKey
        profitLoss
        profitLossPercent
      }
    }
    ytd: slavePerformanceForSpanGet(
      input: { span: YTD, setupStatuses: [ACTIVE, INITIATED, SHORT_CIRCUITED] }
    ) {
      span
      totalProfitLoss
      totalProfitLossPercent
      slavePerformance {
        autoPilotSettingsKey
        profitLoss
        profitLossPercent
      }
    }
  }
  
  
`;

export interface PerformanceData {
    all: PerformanceDataItem;
    day: PerformanceDataItem;
    week: PerformanceDataItem;
    month: PerformanceDataItem;
    ytd: PerformanceDataItem;
}

export interface PerformanceDataItem {
    span: string;
    totalProfitLoss: number;
    totalProfitLossPercent: number;
    slavePerformance: [SlavePerformanceItem]
}
export interface SlavePerformanceItem {
    autoPilotSettingsKey: number;
    profitLoss: number;
    profitLossPercent: number;
}