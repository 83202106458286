import {gql} from "@apollo/client";

export const whoIsIt = gql`
    query whoisit(
        $portfolioKey: Int!){
            whoisit(input: { portfolioKey: $portfolioKey}) {
                portfolio {
                  financialInfo{
                    buyingPower
                    lastHardCheckAt
                    totalValue
                  }
                  connectionStatus
                }
                institution{
                  createdAt
                  institutionKey
                }
            }
        }`;