import {gql} from "@apollo/client";


export const getKYC = gql`
    query kycGet {
        kycGet(input: { remote: false }) {
            kyc {
                email
                phoneNumber
                firstName
                lastName
                birthday
                address1
                address2
                city
                state
                zipCode
                liquidNetWorth
                annualIncome
                generalInvestmentObjective
                riskLevel
            }
        }
    }`;

export const KYC_UPSERT = gql`
    mutation kycUpsert($input: KycUpsertInput!) {
        kycUpsert(input: $input) {
            success
            kyc {
                email
                phoneNumber
                firstName
                lastName
                birthday
                address1
                address2
                city
                state
                zipCode
                liquidNetWorth
                annualIncome
                generalInvestmentObjective
                riskLevel
            }
        }
    }
`;