import { Chart } from 'react-google-charts';
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {useParams} from "react-router-dom";
import {Alert, CircularProgress, Typography} from "@mui/material";
import React from "react";
import ExecutionAccordion from "./ExecutionAccordion";
import {execution, TraceGet} from "../../clients/graphql/pages/trace-visualizer-query";

export default function TraceVisualizer() {

    let {traceID} = useParams();

    const { loading, error, data } = useQueryCoordinate(TraceGet, { variables: { traceId: traceID } });

    if (loading) {
        return <CircularProgress sx={{marginLeft: "40px"}}/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>;
    }

    const curatedData = data?.traceGet?.trace?.executions;

    let executionData =  [];

    executionData.push([
        { type: 'string', label: 'Task ID' },
        { type: 'string', label: 'Task Name' },
        { type: 'string', label: 'Type' },
        { type: 'date', label: 'Start Date' },
        { type: 'date', label: 'End Date' },
        { type: 'number', label: 'Duration' },
        { type: 'number', label: 'Percent Complete' },
        { type: 'string', label: 'Dependencies' },
    ],)

    if (!loading) {
        curatedData?.forEach((ex: execution) => {
            executionData.push([
                ex.executionId,
                ex.queueName,
                'Execution',
                (ex.startedAt === null ? null : new Date(ex.startedAt)),
                (ex.endAt === null ? null : new Date(ex.endAt)),
                null,
                100,
                ex.prevExecutionId,
            ]);
        });
    }

    const options = {
        height: (curatedData.length * 35) + 35,
        gantt: {
            trackHeight: 35,
            criticalPathEnabled: false,
            arrow: {
                angle: 50,
                width: 2,
                radius: 0,
            },
        },
    };

    return (
        <>
            <Typography variant="h6" sx={{paddingBottom: '20px'}}>TraceID: {traceID}</Typography>
            <Chart
                chartType="Gantt"
                width="100%"
                data={executionData}
                options={options}
            />

            <h3>Executions</h3>
            <ExecutionAccordion data={curatedData}/>
        </>
    );
}
