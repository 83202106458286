import {gql} from "@apollo/client";

export const jediBalance = gql`
    mutation( $autoPilotSettingsKey: Int!){
        jediBalance(input: {autoPilotSettingsKey: $autoPilotSettingsKey}) {
            success
            message
        }
    }`;


export  const JediUserDeleteMutation = gql`
    mutation JediUserDelete(
        $hardDelete: Boolean!
        $userKey: Int!
    ){
        JediUserDelete(input:{
            hardDelete: $hardDelete,
            userKey: $userKey
        })  { user {
               firstName,
               lastName,
               userKey
            }
        }
    }`;

export const slaveAutoPilotOrdersGet = gql`
    query($userKey: Int!){
        jediAutoPilotOrdersGet(input: {userKey: $userKey}){
            autoPilotOrders{
                title
                symbol
                orderSide
                averagePrice
                quantity
                transactionAmount
                filledDate
            }
        }
    }`;

export const JediReenableMutation = gql`
    mutation jediReenable(
      $autoPilotSettingsKey: Int!
    ){
      jediReenableSlave(input:{
        autoPilotSettingsKey: $autoPilotSettingsKey,
      }){
        success
        }
      }`;

export const KYC_GET_QUERY = gql`
    query kycGet($remote: Boolean!) {
        kycGet(input: { remote: $remote }) {
            kyc {
                email
                phoneNumber
                firstName
                lastName
                birthday
                address1
                address2
                city
                state
                zipCode
                liquidNetWorth
                annualIncome
                generalInvestmentObjective
                riskLevel
            }
        }
    }
`;

export const ActivePilotsQuery = gql`
    query getSlavePortfolios  {
            slaveAutoPilotPortfoliosGet( 
                input:{
                setupStatuses: [INITIATED, ACTIVE]
            }
        ) {
                slaveAutoPilotPortfolios {
                autoPilotSettingsKey
                masterPortfolio{
                    userFullName
                }
            }
            }
        }`;

export const RecurringInvestmentsQuery = gql`
    query ($autoPilotSettingsKeys: [Int]!){
    autoPilotRecurringInvestmentDataGet( 
    input:{
        autoPilotSettingsKeys: $autoPilotSettingsKeys
    } 
    ){
      autoPilotRecurringInvestmentData{
        autoPilotSettingsKey
        portfolioName
        brokerName
        interval
        intervalCount
        lastDepositAt
        nextDepositAt
        startAt
        createdAt
        status
        amount
  }
} 
    } `;