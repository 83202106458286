import {gql} from "@apollo/client";

export const GQL_WhoamiBasic = gql`
  query {
    whoami {
      userKey
      firstName
      lastName
      email
      phoneNumber
      createdAt
    }
  }
`;