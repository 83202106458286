import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState, useRef } from 'react';
import { useQueryCoordinate } from '../../clients/graphql/graphql.hooks';
import { Alert, Box, InputBase } from '@mui/material';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {jediUserLookup} from "../../clients/graphql/components/search-queries";

const Results = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  position: 'absolute',
  top: '100%',
  width: '100%',
  minWidth: '40ch',
  border: '1px solid grey',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  color: 'black',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}));

export function AutopilotSearchBox() {

  const searchBoxRef = useRef<HTMLInputElement>(null);  // Specifying the type expected for the ref
  const searchResultsRef = useRef<HTMLDivElement>(null); // New ref for the results div

  const [searchValue, setSearchValue] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const { loading, error, data } = useQueryCoordinate(jediUserLookup, {
    variables: {
      searchValue
    },
    skip: searchValue.length < 3
  });

  // Effect is used to hide results when clicking on page
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchBoxRef.current && !searchBoxRef.current.contains(event.target) &&
        searchResultsRef.current && !searchResultsRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBoxRef, searchResultsRef]);

  const users = data?.jediUserLookup?.results ?? [];
  return <Box>
    <Search ref={searchBoxRef}>
      <SearchIconWrapper>
        {loading ? <PendingOutlinedIcon /> : <SearchIcon />}
      </SearchIconWrapper>
      <StyledInputBase
        placeholder='Enter phone or email'
        onFocus={() => setShowResults(true)}
        onBlur={() => setTimeout(() => setShowResults(false), 200)}
        onChange={(e) => setSearchValue(e.target.value)}
        inputProps={{ 'aria-label': 'search' }}
      />
      {showResults && users.length > 0 &&
        <Results ref={searchResultsRef}>
          {error && <Alert severity="warning">INTERNAL: there was an issue searching for users</Alert>}
          <List sx={{
            overflow: 'auto',
            maxHeight: 300,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            borderRadius: '8px',
          }}>
            {users.map((user: any, index: number) => <UserSearchResult user={user} key={index} addDivider={index !== 0} />)}
          </List>
        </Results>
      }
    </Search>
  </Box>;
}

function UserSearchResult(props: any) {
  return <>
    {props.addDivider && <Divider component='li' />}
    <ListItem component={Link} to={`/users/${props.user.userKey}`} disablePadding>
      <ListItemButton>
        <ListItemText
          primary={`${props.user.firstName} ${props.user.lastName}`}
          sx={{
            color: 'black',
          }}
          secondary={`${props.user.phoneNumber?.replace(/(\d{0,1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4' ?? '')} | ${props.user.email}`}
        />
      </ListItemButton>
    </ListItem>
  </>;
}