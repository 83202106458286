import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Alert, Button, CircularProgress, Grid, Paper, styled} from "@mui/material";
import InstitutionCrudPanel from "../../views/institutions/InstitutionCrudPanel";
import {useParams} from "react-router-dom";
import {useMutationAsUser, useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {InstitutionPortfoliosList} from "../../views/institutions/InstitutionPortfoliosList";
import {InstitutionAuthLogsTable} from "../../components/tables/InstitutionAuthLogsTable";
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmAlertDialog from "../../components/dialogs/confirm-alert";
import {InstitutionTimestampsPanel} from "../../views/institutions/InstitutionTimestampsPanel";
import {
    disconnectInstitutionMutation,
    getInstitutionDetails,
    refreshAuthTokenMutation
} from "../../clients/graphql/pages/users-queries";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 10,
    minHeight: 'calc(100% - 25px)',
}));

export function UserInstitutionPage() {
    const { institutionKey  } = useParams();
    const intInstitutionKey = parseInt(institutionKey as string);

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {variables: {institutionKey: intInstitutionKey}});
    const curatedData = data?.whoisit?.institution;

    const [jediDisconnectInstitutionMutation, jediDisconnectInstitutionMutationState] = useMutationAsUser(disconnectInstitutionMutation, {variables: {institutionKey: intInstitutionKey, userKey: parseInt(data?.whoisit?.institution?.userKey)}})

    const [jediRefreshAuthTokenMutation, jediRefreshAuthTokenState] = useMutationAsUser(refreshAuthTokenMutation, { userKey: data?.whoisit?.institution?.userKey, variables: {institutionKey: intInstitutionKey}});


    if (loading) {
        return <CircularProgress sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}}/>
    }

    if (error || jediRefreshAuthTokenState.error || jediDisconnectInstitutionMutationState.error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }

    return (
        <Box>
            <Typography variant={"h4"}>Institution: {institutionKey}</Typography>
            <br/>
            <Grid container spacing={1} xs={4} style={{paddingTop: "10px"}}>
                <Button
                    sx={{marginLeft: "10px"}}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    color="primary"
                    startIcon={<RefreshIcon/>}
                    onClick={() => {
                        jediRefreshAuthTokenMutation()
                    }}
                >
                    Refresh Auth Token
                </Button>
                <ConfirmAlertDialog
                    title='Are you sure you want to disconnect?'
                    description='Disconnecting institution will disconnect ALL portfolios as well'
                    actionButtonTitle='Disconnect Institution'
                    onConfirm={() => {
                        jediDisconnectInstitutionMutation()
                    }}
                    buttonComponent={
                        <Button
                            sx={{marginLeft: "10px", color: "red", borderColor: "red"}}
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseIcon/>}
                        >
                            Disconnect Institution
                        </Button>
                    }
                />
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <InstitutionCrudPanel institutionKey={intInstitutionKey} userKey={data?.whoisit?.institution?.userKey}/>
                </Grid>
                <Grid item xs={4}>
                    <InstitutionPortfoliosList portfolios={curatedData?.portfolios}/>
                </Grid>
                <Grid item xs={4}>
                    <InstitutionTimestampsPanel institutionKey={intInstitutionKey}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{height: 500}}>
                <Grid item xs={7.25}>
                    <Item>
                        <InstitutionAuthLogsTable institutionKey={intInstitutionKey}/>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}