import {gql} from "@apollo/client";

export const jediEquilibriumHealthQuery = gql`
    query{
      jediEquilibriumHealthQuery{
        stats{
          type,
          todaysP90,
          lastWeekP90,
          todaysP50,
          lastWeekP50
        }
      }
    }`;