import { useQueryAsUser } from '../../clients/graphql/graphql.hooks';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import {Box, CircularProgress, Skeleton, Tooltip, Typography} from '@mui/material';
import * as _ from 'lodash';
import {
    JediIndividualPosition,
    JediPortfolioPosition,
    jediPortfolioPositionsGet
} from "../../clients/graphql/components/tables-queries";

interface Props {
    userKey: number;
    portfolioKey: number;
    autoPilotSettingsKey?: number;
};

const getPositionRemoteHealth = (position: JediPortfolioPosition) => {
    const localQuantity = position.localPosition?.quantity || 0;
    const remoteQuantity = position.remotePosition?.quantity || 0;
    const manualQuantity = position.manualPosition?.quantity || 0;
    const pilotedQuantity = _.sumBy(position.pilotedPositions, 'quantity') || 0;

    const deltaThreshold = 0.0001; // 0.01% in decimal form

    const localVsRemoteDelta = Math.abs(localQuantity - remoteQuantity);
    const localVsPilotedAndManualDelta = Math.abs(localQuantity - (pilotedQuantity + manualQuantity));

    const localVsRemoteHealth = localVsRemoteDelta <= deltaThreshold * localQuantity;
    const localVsPilotedAndManualHealth = localVsPilotedAndManualDelta <= deltaThreshold * localQuantity;

    const bothExist = localQuantity > 0 && remoteQuantity > 0;
    const overallHealth = localVsRemoteHealth && localVsPilotedAndManualHealth && bothExist;

    const deltas = {
        localVsRemote: localQuantity - remoteQuantity,
        localVsPilotedAndManual: localQuantity - pilotedQuantity - manualQuantity,
    };

    return { overallHealth, localVsRemoteHealth, localVsPilotedAndManualHealth, bothExist, deltas };
};

export const PortfolioPositionsTable: React.FC<Props> = ({ userKey, portfolioKey }) => {
    const response = useQueryAsUser(jediPortfolioPositionsGet, { userKey, portfolioKey, variables: { userKey, portfolioKey } });
    const positionData: JediPortfolioPosition[] = response.data?.jediPortfolioPositionsGet?.positions;
    const positionError = response.error;
    const positionLoading = response.loading;
    
    if (positionLoading) {
        return <CircularProgress sx={{marginLeft: 'calc(50% - 10px)'}}/>
    }

    if (positionError) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns: GridColDef[] = [
        { 
            field: 'isHealthy', 
            headerName: 'Data Health', 
            width: 120,
            description: 'The health of the position in terms of equality between local and remote positions',
            renderCell: (params: GridRenderCellParams) =>  {
                if(positionLoading) {
                    return <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                  }}><Skeleton width={25} variant='rounded' sx={{ fontSize: '1rem' }} /></div>
                }
                if(positionError) return <Tooltip title='Error Loading Position Health'><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>
                const portfolioPosition = positionData.find((p) => p.symbol === params.row.symbol);
                const positionHealth = portfolioPosition ? getPositionRemoteHealth(portfolioPosition) : { overallHealth: false, localVsRemoteHealth: false, localVsPilotedAndManualHealth: false, bothExist: false, deltas: { localVsRemote: 0, localVsPilotedAndManual: 0 } };
                const positionDataIsHealthy = positionHealth.overallHealth;
                // find which health is not healthy and put into a nice descriptive string
                const healthReasons = [];
                if (!positionHealth.localVsRemoteHealth) healthReasons.push(`local vs remote ${positionHealth.deltas.localVsRemote}`);
                if (!positionHealth.localVsPilotedAndManualHealth) healthReasons.push(`local vs piloted + manual ${positionHealth.deltas.localVsPilotedAndManual}`);
                if (!positionHealth.bothExist) healthReasons.push('missing remote or local position');
                return (
                <Tooltip title={!positionDataIsHealthy ? `Unhealthy: ${healthReasons.join(', ')}` : 'Healthy'} sx={{marginLeft: '4rem'}}>
                    <div>
                        {positionDataIsHealthy && <DoneIcon sx={{marginLeft: '2rem'}} color='success' style={{ verticalAlign: 'middle'}} />}
                        {!positionDataIsHealthy && <AlertIcon sx={{marginLeft: '2rem'}} color='error' style={{ verticalAlign: 'middle'}} />}
                    </div>
                </Tooltip>)
            }
        },
        { field: 'symbol', headerName: 'Symbol', width: 90, description: 'The symbol of the position' },
        { field: 'remoteQuantity', headerName: 'Remote', width: 100, description: 'The quantity of the position from the broker' },
        { field: 'localQuantity', headerName: 'Local', width: 100, description: 'The quantity of the position in the local database' },
        { field: 'pilotedQuantity', headerName: 'Piloted', width: 100, description: 'The quantity of the position piloted' },
        { field: 'manualQuantity', headerName: 'Manual', width: 100, description: 'The quantity of the position manually set' },
        { field: 'pilots', headerName: 'Pilots', width: 300, description: 'The pilots piloting the position' },
    ];

    const rows = _.orderBy(positionData.map((position: JediPortfolioPosition) => {
        return {
            id: position.symbol,
            symbol: position.symbol,
            broker: position.portfolio.brokerName,
            connectionStatus: position.portfolio.connectionStatus,
            remoteQuantity: position.remotePosition?.quantity,
            localQuantity: position.localPosition?.quantity,
            manualQuantity: position.manualPosition?.quantity,
            pilotedQuantity: _.sumBy(position.pilotedPositions, 'quantity'),
            pilots: position.pilotedPositions.map((pilot: JediIndividualPosition) => pilot.pilotName).join(', '),
            isHealthy: getPositionRemoteHealth(position).overallHealth ? 1 : 0,
        }
    }), 'amount', 'desc');

    const adminURL = process.env.REACT_APP_LEGACY_ADMIN_URL || 'http://localhost:4000/49affa5e-ed96-4334-b05f-b964ead92337/admin'

    return (
        <Box sx={{height: '30rem'}}>
            <Typography sx={{
                color: '#6D6D6D',
                font: 'Roboto',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '12px',
                letterSpacing: '0.05em',
                marginTop: '1rem',
                marginLeft: '1rem',
                paddingBottom: '.75rem',
            }}>
                PORTFOLIO POSITIONS
            </Typography>
            <DataGrid
                rowHeight={40}
                columns={columns} 
                rows={rows} 
                loading={positionLoading}
                hideFooter={true}
                onRowClick={(params) => window.open(`${adminURL}/position/details/${portfolioKey}/${params.row.symbol}/EQUITY`, '_blank')}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'isHealthy', sort: 'asc' },
                        ],
                    },
                }}
                sx = {{marginLeft: '1rem', marginRight: '1rem', height: '26rem'}}
            />
        </Box>
    );
};