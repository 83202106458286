import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    Box,
    Card,
    CardContent
} from "@mui/material";
import React, { useState } from "react";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {
    KYC_GET_QUERY
} from "../../clients/graphql/components/buttons-queries";

interface KYCdataButtonProps {
    userKey: number;
}


export function KYCdataButton({ userKey }: KYCdataButtonProps): JSX.Element {
    const [open, setOpen] = useState(false);

    const { data: remoteData, loading: remoteLoading } = useQueryAsUser(KYC_GET_QUERY, {
        userKey: userKey,
        variables: { remote: true },
    });

    const { data: localData, loading: localLoading } = useQueryAsUser(KYC_GET_QUERY, {
        userKey: userKey,
        variables: { remote: false },
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                size={'small'}
                color={'primary'}
                onClick={handleOpen}
            >
                KYC Data
            </Button>

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>KYC Data</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {localLoading ? (
                                <Typography>Loading...</Typography>
                            ) : localData?.kycGet?.kyc ? (
                                <Box sx={{ padding: 3 }}>
                                    <Typography variant="h5" gutterBottom>
                                        Local Data
                                    </Typography>

                                    <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>First Name:</strong> {localData?.kycGet?.kyc.firstName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Last Name:</strong> {localData?.kycGet?.kyc.lastName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2"><strong>Email:</strong> {localData?.kycGet?.kyc.email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Address 1:</strong> {localData?.kycGet?.kyc.address1}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Address 2:</strong> {localData?.kycGet?.kyc.address2}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>City:</strong> {localData?.kycGet?.kyc.city}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>State:</strong> {localData?.kycGet?.kyc.state}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Zip Code:</strong> {localData?.kycGet?.kyc.zipCode}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Birthday:</strong> {localData?.kycGet?.kyc.birthday}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Liquid Net Worth:</strong> {localData?.kycGet?.kyc.liquidNetWorth}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Annual Income:</strong> {localData?.kycGet?.kyc.annualIncome}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>General Investment Objective:</strong> {localData?.kycGet?.kyc.generalInvestmentObjective}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Risk Level:</strong> {localData?.kycGet?.kyc.riskLevel}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                        ) : (
                                <Typography>No data found</Typography>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            {remoteLoading ? (
                                <Typography>Loading...</Typography>
                            ) : remoteData?.kycGet?.kyc ? (
                                <Box sx={{ padding: 3 }}>
                                    <Typography variant="h5" gutterBottom>
                                        Institution Data
                                    </Typography>
                                    <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>First Name:</strong> {remoteData?.kycGet?.kyc.firstName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Last Name:</strong> {remoteData?.kycGet?.kyc.lastName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2"><strong>Email:</strong> {remoteData?.kycGet?.kyc.email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Address 1:</strong> {remoteData?.kycGet?.kyc.address1}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Address 2:</strong> {remoteData?.kycGet?.kyc.address2}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>City:</strong> {remoteData?.kycGet?.kyc.city}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>State:</strong> {remoteData?.kycGet?.kyc.state}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Zip Code:</strong> {remoteData?.kycGet?.kyc.zipCode}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Birthday:</strong> {remoteData?.kycGet?.kyc.birthday}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Liquid Net Worth:</strong> {remoteData?.kycGet?.kyc.liquidNetWorth}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Annual Income:</strong> {remoteData?.kycGet?.kyc.annualIncome}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>General Investment Objective:</strong> {remoteData?.kycGet?.kyc.generalInvestmentObjective}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1"><strong>Risk Level:</strong> {remoteData?.kycGet?.kyc.riskLevel}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            ) : (
                                <Typography>No data found</Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
