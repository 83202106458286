import React, {useState} from 'react';
import './css/home-screen-style.css';
import Draggable from 'react-draggable';
import SlavePortfolioCard from './components/slave-portfolio-card';
import SlavePortfolioHeader from './components/slave-portfolio-header';
import {Box, CircularProgress, Typography} from '@mui/material';
import {useQueryAsUser} from '../../../clients/graphql/graphql.hooks';
import {SlaveDetailScreen} from "../detail/slave-detail-screen";
import {getSlavePortfoliosQuery} from "../../../clients/graphql/pages/users-queries";
import {allPerformanceSpansGetQuery} from "../../../clients/graphql/views/mobile-queries";

export const HomeScreen = ({ userKey, setParams, onChangeOptionHome, currentOptionHome, slavePortfolio, slaveDataByAutopilotSetting, onDialogClose}) => {
    const { data: dataSlave, loading: loadingSlave, error: errorSlave } = useQueryAsUser(getSlavePortfoliosQuery, { userKey,appVersionEnabled:true , variables: { userKey } });
    const { data: dataPerformance, loading: loadingPerformance, error: errorPerformance } = useQueryAsUser(allPerformanceSpansGetQuery, { userKey, appVersionEnabled:true , variables: { userKey } });

    const [dragging, setDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);

    const slavePerformanceData = dataPerformance ? dataPerformance[currentOptionHome].slavePerformance : null;

    const handleDrag = (e, data) => {
        setDragging(true);
        setDragOffset(data.y);
    };

    const handleStop = () => {
        if (dragOffset > 10) {
            setTimeout(() => {
                setDragging(false);
            }, 10); // Slight delay to distinguish between drag and click
        } else {
            setDragging(false);
        }
        setDragOffset(dragOffset);
        setDragging(false);
    };

    const handleCardClick = (slave, dataPerformance, e) => {
        if (dragging) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setParams(slave, dataPerformance);
        }
    };

    return (
        <Box sx={{
            margin: '0.5rem',
        }}>
            <Typography variant="subtitle1" align={"left"}>CUSTOMER VIEW</Typography>
            <Box sx={{
                height: '55.7rem',
                overflowY: 'scroll',
                borderRadius: '12px',
            }}>
                {loadingPerformance ? (
                    <div>Loading</div>
                ) : errorPerformance ? (
                    <div>Some error happens</div>
                ) : (
                    <SlavePortfolioHeader
                        isLoading={loadingPerformance}
                        isError={errorPerformance}
                        data={dataPerformance}
                        currentOption={currentOptionHome}
                        onChangeOption={onChangeOptionHome}
                        dataSlave={dataSlave?.slaveAutoPilotPortfoliosGet}
                    />
                )}
                <SlaveDetailScreen
                    slavePortfolio={slavePortfolio}
                    slaveDataByAutopilotSetting={slaveDataByAutopilotSetting}
                    onDialogClose={onDialogClose}
                />
                <div style={{height: 5}}></div>
                <Draggable
                    axis="y"
                    bounds="parent"
                    onDrag={handleDrag}
                    onStop={handleStop}
                    position={{x: 0, y:  0}}
                >
                    <div className="cards-container">
                        {loadingSlave ? (
                            <CircularProgress size={40} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto", marginBottom: "10px"}} />
                        ) : errorSlave ? (
                            <div style={{marginTop: '10.5rem', color: 'red'}}>Failure to fetch portfolios</div>
                        ) : (
                            dataSlave?.slaveAutoPilotPortfoliosGet.slaveAutoPilotPortfolios.map((slave, index) => {
                                const slaveDataByAutopilotSetting = slavePerformanceData?.find(
                                    (f) => f.autoPilotSettingsKey === slave.autoPilotSettingsKey
                                );
                                const result = slaveDataByAutopilotSetting
                                    ? `${slaveDataByAutopilotSetting?.profitLoss?.toFixed(2)}  (${(slaveDataByAutopilotSetting?.profitLossPercent * 100).toFixed(1)}%)`
                                    : '0.00 + (0.00%)';

                                const dragEffect = dragging ? dragOffset * (index * 0.4) : 0;

                                return (
                                    <div
                                        key={index}
                                        className="card-wrapper"
                                        style={{
                                            zIndex: index + 1,
                                            transform: `translateY(${index * 65 + dragEffect}px)`
                                        }}
                                        onClick={(e) => handleCardClick(slave, dataPerformance, e)}
                                    >
                                        <SlavePortfolioCard
                                            title={slave.masterPortfolio.userFullName}
                                            subtitle={(slave.autoPilotSettings.setupStatus === 'INITIATED') ? 'FREE PLAN' : ''}
                                            amount={(slave.pendingTransferData?.amount ?? 0.0) !== 0.0 ? (slave.pendingTransferData?.amount) : slave?.totalValue}
                                            isPendingTransfer={slave.pendingTransferData?.amount ?? 0.0 !== 0.0}
                                            change={result}
                                            isLosingMoney={slaveDataByAutopilotSetting?.profitLoss < 0}
                                            imageSrc={slave.masterPortfolio.portfolioCardImageUrl}
                                            virtualText={slave.autoPilotSettings.slavePortfolio.portfolioName}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </div>
                </Draggable>
            </Box>
        </Box>
    );
};
