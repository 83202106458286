import React from 'react';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import {useMutationCoordinate} from '../../clients/graphql/graphql.hooks';
import ConfirmAlertDialog from '../../components/dialogs/confirm-alert';
import {BROKER_NAME, CONNECTION_STATUS} from '../../shared/portfolio-types';
import {jediReenableSlave} from "../../clients/graphql/views/pilots-queries";

export interface UserPilotOverviewData {
    userKey: number;
    autoPilotSettingsKey: number;
    pilot: string;
    allocation: number;
    status: string;
    portfolio: {
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
        portfolioKey: number;
    };
    portfolioConnectionState: string;
    amountPending: number;
    initiatedAt: Date;
    equilibriumState: string;
    isManualExecution: boolean;
}

interface UserPilotOverviewProps {
    data: UserPilotOverviewData;
}


export const UserPilotDetailsOverview: React.FC<UserPilotOverviewProps> = ({ data }) => {
    const navigator = useNavigate();

    const [reenableMutation] = useMutationCoordinate(jediReenableSlave, {variables: {autoPilotSettingsKey: data.autoPilotSettingsKey}});

    const handleReenable = async () => {
        // const confirmed = confirm('Re-enabling the pilot will resume the autopilot. Are you sure you want to proceed?');
        // if(!confirmed) return;
        await reenableMutation();
        window.location.reload();
    }

    type StyledTableCellProps = {
        children: string,
    }

    const StyledTableCell: React.FC<StyledTableCellProps> = ({ children }) => {
        return (
            <TableCell
                sx={{
                    color: '#6D6D6D',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '12px',
                    letterSpacing: '0.05em',
                    paddingBottom: '.75rem',
                }}
            >
                {children}
            </TableCell>
        );
    };

    return (
        <Paper elevation={1} sx={{
            width: '100%',
            padding: 1,
            height: 1,
            marginTop: 1,
        }}><Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell children={'Customer'}/>
                                <TableCell>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="outlined"
                                        tabIndex={-1}
                                        color='primary'
                                        endIcon={<ArrowForwardOutlinedIcon/>}
                                        onClick={() => navigator(`/users/${data.userKey}`)}
                                    >
                                        #{data.userKey}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Portfolio</StyledTableCell>
                                <TableCell>
                                    <Tooltip title={`portfolio is ${_.lowerCase(data.portfolioConnectionState)}`}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            tabIndex={-1}
                                            color={data.portfolioConnectionState === 'DISCONNECTED' ? 'error' : 'success'}
                                            startIcon={data.portfolioConnectionState === 'DISCONNECTED' && (
                                                <ErrorIcon style={{color: 'red'}}/>
                                            )}
                                            endIcon={<ArrowForwardOutlinedIcon/>}
                                            onClick={() => navigator(`/portfolios/${data.portfolio.portfolioKey}`)}
                                        >
                                            {_.capitalize(data.portfolio.brokerName)}
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Status</StyledTableCell>
                                <TableCell>
                                    {data.status === 'SHORT_CIRCUITED' ? (
                                        <React.Fragment>
                                            <ConfirmAlertDialog
                                                title='Are you sure you want to re-enable?'
                                                description='Re-enabling will turn on Autopilot for this customer'
                                                actionButtonTitle='Re-enable'
                                                onConfirm={handleReenable}
                                                buttonComponent={
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='error'
                                                        startIcon={<ErrorIcon style={{color: 'red'}}/>}
                                                        endIcon={<ArrowForwardOutlinedIcon/>}
                                                    >
                                                        Re-enable
                                                    </Button>
                                                }
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <>
                                            {data.status === 'ACTIVE' && (
                                                <Tooltip title="Active">
                                                    <Button
                                                        disableRipple={true}
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='success'
                                                        startIcon={<CheckCircleOutlinedIcon
                                                            style={{color: 'success'}}/>}
                                                    >Active</Button>
                                                </Tooltip>
                                            )}
                                            {data.status === 'DELETED' && (
                                                <Tooltip title="Deleted">
                                                    <Button
                                                        disableRipple={true}
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='error'
                                                        startIcon={<DeleteOutlinedIcon style={{color: 'warning'}}/>}
                                                    >Deleted</Button>
                                                </Tooltip>
                                            )}
                                            {!['SHORT_CIRCUITED', 'DELETED', 'ACTIVE'].includes(data.status) && (data.status.split('_').map(_.capitalize).join(' '))}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell> Allocation</StyledTableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>${data.allocation.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).slice(1)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Amount Pending</StyledTableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>${data.amountPending.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).slice(1)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Started At</StyledTableCell>
                                <TableCell>{moment(data.initiatedAt).format('MM/DD/YYYY HH:MM')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Execution
                                    Type</StyledTableCell>
                                <TableCell> {data.isManualExecution ? 'MANUAL' : 'AUTOMATIC'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid></Paper>
    );
};
