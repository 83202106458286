import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import { Grid, Tooltip, Paper, styled, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import moment from 'moment';
import * as _ from 'lodash';
import {StockSymbolComponent} from "../../pages/users/StockSymbolComponent";
import {CustomTriggerHistoryOrdersItem, TriggerHistoryItem} from "../../clients/graphql/components/tables-queries";

const buys = ['RECURRING_INVESTMENT', 'MANUAL_DEPOSIT'];
const sells = ['MANUAL_WITHDRAW', 'MASTER_DELETED'];

export function totalDeposits(rows: TriggerHistoryItem[]) {
    let deposited = 0;
    let withdrawn = 0; // will be negative
    let total = 0;

    for(let row of rows){
        if(buys.includes(row.type)){
            deposited += row.transactionAmount;
        }
        else if(sells.includes(row.type)){
            withdrawn += row.transactionAmount;
        }
    }
    total = deposited + withdrawn;

    return(
        total
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '10px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 10,
    height: 'calc(100% - 25px)',
    minHeight: '40vh',
}));

const columns: GridColDef[] = [
    { 
        field: 'symbol', 
        headerName: 'Symbol', 
        width: 150, 
        align: 'left',
        renderCell: (params) => (
            <StockSymbolComponent logo={params.row.pictureUrl} symbol={params.row.symbol} value={params.row.symbol} />
        )
    },
    { 
        field: 'orderSide', 
        headerName: 'Order Side',
        renderCell: (params: GridRenderCellParams) => (
            <Tooltip title={params.value.split('_').map(_.capitalize).join(' ')}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', color: params.row.typeColor }}>
                    {params.value === 'BUY' && <TrendingUpOutlinedIcon color='success'/>}
                    {params.value === 'SELL' && <TrendingDownOutlinedIcon color='error'/>}
                    <span>{params.value.split('_').map(_.capitalize).join(' ')}</span>
                </div>
            </Tooltip>
        )
    },
    { 
        field: 'orderState', 
        headerName: 'Status', 
        width: 80,
        renderCell: (params: GridRenderCellParams) => (
            <Tooltip title={['FILLED', 'PENDING'].includes(params.value) && !params.row.failureReason ? _.capitalize(params.value) : params.row.failureReason}>
                <div>
                    {params.value === 'FILLED' && <DoneIcon color='success' style={{ verticalAlign: 'middle'}} />}
                    {params.value === 'PENDING' && params.row.orderStatus !== 'FAILED' && <PendingIcon color='warning' style={{ verticalAlign: 'middle'}} />}
                    {params.value === 'CANCELED' && <AlertIcon color='error' style={{ verticalAlign: 'middle'}} />}
                    {params.row.orderStatus === 'FAILED' && <AlertIcon color='error' style={{ verticalAlign: 'middle'}} />}
                </div>
            </Tooltip>
        )
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        renderCell: (params: GridRenderCellParams) => {
            return parseFloat(params.value).toFixed(4);
        }
    },
    { field: 'averagePrice', headerName: 'Average Price', width: 150, valueFormatter: (val) => `$${_.round(val, 2)}` },

    { field: 'fullfilledAt', headerName: 'Date', width: 200, renderCell: (params: GridRenderCellParams) => {
            if(params.row.fullfilledAt){
                return moment(params.row.fullfilledAt).format('MM/DD/YYYY HH:mm:ss A')
            }
            else if(params.row.fullfilledAt == null){
                return moment(params.row.createdAt).format('MM/DD/YYYY HH:mm:ss A')
            }
        } },

    { field: 'transactionAmount', headerName: 'Total Cost', valueFormatter: (val) => `$${val}` },
];

interface TriggerHistoryOrdersTableProps {
    targetAmount: number;
    props: CustomTriggerHistoryOrdersItem[];
    rows: TriggerHistoryItem[];
    createdAt: Date;
}

export const TriggerHistoryOrdersTable: React.FC<TriggerHistoryOrdersTableProps> = ({ props, targetAmount, rows, createdAt}) => {
    const details: CustomTriggerHistoryOrdersItem[] = props;
    const netBuyAmount = _.round(_.sumBy(details.filter((item) => item.orderState === 'FILLED' && item.orderSide === 'BUY'), 'transactionAmount'), 2);
    const netSellAmount = _.round(_.sumBy(details.filter((item) => item.orderState === 'FILLED' && item.orderSide === 'SELL'), 'transactionAmount'), 2);

    const totalDeposited = totalDeposits(rows);
    return (
        <Grid item>
            <Item>
                <Typography variant="h6" component="div" padding={2}>
                    Order History for Trigger {targetAmount ? ` - Target Amount $${targetAmount}` : ''}
                </Typography>
                <div style={{paddingBottom: 10}}>
                    <Typography variant="subtitle1" component="span" style={{ marginLeft: 10 }}>
                        <strong>Net Buy Amount:</strong> ${netBuyAmount.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" component="span" style={{ marginLeft: 10 }}>
                        <strong>Net Sell Amount:</strong> ${netSellAmount.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" component="span" style={{ marginLeft: 10 }}>
                        <strong>Total Deposited:</strong> ${totalDeposited.toLocaleString()}
                    </Typography>
                </div>
                <DataGrid
                    rows={details.map((row, index) => ({ ...row, id: index, pictureUrl: row.asset.pictureUrl }))}
                    columns={columns}
                    rowHeight={40}
                    autoHeight
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: 'symbol', sort: 'asc' },
                                { field: 'orderState', sort: 'desc' },
                            ],
                        },
                    }}
                />
            </Item>
        </Grid>
    );
}

