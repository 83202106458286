import {gql} from "@apollo/client";

export interface execution {
    executionId: string
    prevExecutionId: string
    queueName: string
    startedAt: Date
    endAt: Date
    triggeredAt: string
}

export const TraceGet = gql`
    query traceGet($traceId: String!) {
        traceGet(input:{
            traceId: $traceId
        }) {
            trace {
                traceId
                executions {
                    executionId
                    prevExecutionId
                    queueName
                    startedAt
                    endAt
                    triggeredAt
                }
            }
        }
}`;