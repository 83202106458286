import {
    Alert,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import WifiIcon from '@mui/icons-material/Wifi';
import React from "react";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import SavingsIcon from '@mui/icons-material/Savings';
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import moment from "moment/moment";
import {getInstitutionDetails} from "../../clients/graphql/views/institutions-queries";

interface props{
    institutionKey: number
}

export function InstitutionTimestampsPanel({institutionKey}: props) {

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {variables: {institutionKey: institutionKey}});

    if (loading) {
        return <CircularProgress/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }
    const curatedData = data?.whoisit?.institution;

    return (
        <Paper elevation={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 1.5 }}>
                <Typography variant="h6" component='div' >
                    Authentication Data
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <WifiIcon />
                        </ListItemIcon>
                        <ListItemText primary={`MFA Type: ${curatedData?.mfaType}`} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            <EventBusyIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Access Token Expires At: ${moment(curatedData?.gzpcExpiresAt).format('MM/DD/YYYY HH:mm')}`} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            <SavingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary={`Backup Witholding: ${(curatedData?.hasBackupTaxWithholding === null ? "UNKNOWN" : curatedData?.hasBackupTaxWithholding)}`} />
                    </ListItem>
                </List>
            </Box>
        </Paper>
    )
}