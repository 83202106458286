import {
    Alert,
    CircularProgress,
} from "@mui/material";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {jediEquilibriumHealthQuery} from "../../clients/graphql/pages/trigger-type-tile-query";

export function TriggerTypeTile () {

    const {loading, error, data} = useQueryCoordinate(jediEquilibriumHealthQuery, {});


    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'jediEquilibriumHealthQuery'</Alert>;
    }

    let tableData = data?.jediEquilibriumHealthQuery?.stats;

    const columns = [
        { field: 'id', headerName: 'Type', width: 195 },
        {
            field: 'todaysP50',
            headerName: 'todaysP50',
            width: 95,
        },
        {
            field: 'todaysP90',
            headerName: 'todaysP90',
            width: 95,
        },
        {
            field: 'lastWeekP50',
            headerName: 'lastWeekP50',
            width: 95,
        },
        {
            field: 'lastWeekP90',
            headerName: 'lastWeekP90',
            width: 95,
        }
    ];

    const rows = tableData.map(row => {
        return {
            id: row.type,
            todaysP50: row.todaysP50,
            lastWeekP50: row.lastWeekP50,
            todaysP90: row.todaysP90,
            lastWeekP90: row.lastWeekP90,
        };
    });

    return (
        <div style={{maxWidth: 580, marginLeft: "15px"}}>
            <Typography sx={{paddingLeft: "13px ", paddingTop: "10px", paddingBottom: "15px", fontWeight: "bold"}} component="h1" variant="h5">
                Trigger Type Health
            </Typography>
            <Box sx={{ height: 370, width: '100%' }} elecation={4}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                />
            </Box>
        </div>
    )
}