import {CircularProgress, Paper, Tooltip, Typography} from "@mui/material";
import {DataGrid, GridColDef, } from "@mui/x-data-grid";
import { useQueryAsUser } from "../../clients/graphql/graphql.hooks";
import moment from "moment";
import {StockSymbolComponent} from "../../pages/users/StockSymbolComponent";
import React from "react";
import Box from "@mui/material/Box";
import {jediPortfolioRestrictionsGet, PortfolioRestriction} from "../../clients/graphql/components/tables-queries";

export const PortfolioRestrictionsTable: React.FC<{ userKey: number, portfolioKey: number}> = ({ userKey, portfolioKey }) => {
    const response = useQueryAsUser(jediPortfolioRestrictionsGet, { userKey, portfolioKey, variables: { userKey, portfolioKey } });
    const data = response.data?.portfolioGet?.portfolio?.restrictions || [];
    const sorted = [...data].sort((a: PortfolioRestriction, b: PortfolioRestriction) => a?.asset?.symbol.localeCompare(b?.asset?.symbol));
    const error = response.error;
    const loading = response.loading;

    if (loading) {
        return <CircularProgress sx={{marginLeft: 'calc(50% - 10px)'}}/>
    }

    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns: GridColDef[] = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            width: 120,
            renderCell: (params) => (
                <StockSymbolComponent symbol={params.row.symbol} logo={params.row.pictureUrl}
                                      value={params.row.symbol}/>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            valueFormatter: (value) => value ? moment(value).format('MM/DD/YYYY HH:mm') : ''
        },
        {
            field: 'reason',
            headerName: 'Reason',
            width: 450 ,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                    <Tooltip title={portfolioRestrictionExplaination(params.row.reason)}>
                        <Typography
                            title={portfolioRestrictionExplaination(params.row.reason)}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: 'small',
                            }}>{portfolioRestrictionExplaination(params.row.reason)}</Typography>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const rows = sorted.map((row: PortfolioRestriction, index: number) => {
        return {
            id: index,
            symbol: row.asset.symbol,
            createdAt: row.createdAt,
            reason: row.reason,
            pictureUrl: row.asset.pictureUrl
        }
    });

    return <Paper elevation={1} sx={{
        width: '100%',
        padding: 2,
        height: 1,
    }}><Box style={{height: '22rem', width: 'auto'}}>
        <Typography align={'left'} sx={{
            color: '#6D6D6D',
            font: 'Roboto',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '12px',
            letterSpacing: '0.05em',
            paddingBottom: '.75rem',
        }}>
            PORTFOLIO RESTRICTIONS
        </Typography>
        <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={40}
            loading={loading}
            hideFooter={true}
            sx={{
                height: '21rem'
            }}
        />
    </Box></Paper>
}

export const portfolioRestrictionExplaination = (reason: string) => {
    switch (reason) {
        case 'UNSIGNED_DESIGNATED_INVESTMENTS_AGREEMENT':
            return `
                Security is unable to be traded without the customer
                signing a Designated Investment Agreement in their
                brokerage account
            `;
        default:
            return "Unknown";
    }
}