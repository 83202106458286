import {Alert, Button, Chip, Dialog, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {useMutationAsUser, useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {getKYC, KYC_UPSERT} from "../../clients/graphql/components/status-queries";

interface KYCStatusInput {
    kycState: string;
    userKey: number;
    institutionKey: number
}

enum LIQUID_NET_WORTH {
    RANGE_5K_OR_BELOW = "5K or Below",
    RANGE_5K_TO_25K = "5K to 25K",
    RANGE_25K_TO_50K = "25K to 50K",
    RANGE_50K_TO_100K = "50K to 100K",
    RANGE_100K_TO_250K = "100K to 250K",
    RANGE_250K_TO_500K = "250K to 500K",
    RANGE_500K_TO_1M = "500K to 1M",
    RANGE_1M_AND_ABOVE = "1M and Above"
}

enum ANNUAL_INCOME {
    RANGE_25K_OR_BELOW = 'RANGE_25K_OR_BELOW',
    RANGE_25K_TO_50K = "RANGE_25K_TO_50K",
    RANGE_50K_TO_100K = "RANGE_50K_TO_100K",
    RANGE_100K_TO_200K = "RANGE_100K_TO_200K",
    RANGE_200K_TO_500K = "RANGE_200K_TO_500K",
    RANGE_500K_AND_ABOVE = "RANGE_500K_AND_ABOVE"
}

enum INVESTMENT_OBJECTIVE {
    PRESERVE = "PRESERVE",
    BALANCE = "BALANCE",
    GROW = "GROW"
}

enum RISK_LEVEL {
    LOWEST = "LOWEST",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    HIGHEST = "HIGHEST"
}

export function KycStatusChip({ kycState, userKey, institutionKey }: KYCStatusInput) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

    const [kycUpsertMutation, kycUpsertMutationStatus] = useMutationAsUser(KYC_UPSERT, {
        userKey: userKey,
        variables: {
            input: {
                institutionKey: institutionKey,
                kyc: {
                    email: formData?.email,
                    phoneNumber: formData?.phoneNumber,
                    firstName: formData?.firstName,
                    lastName: formData?.lastName,
                    birthday: formData?.birthday,
                    address1: formData?.address1,
                    address2: formData?.address2,
                    city: formData?.city,
                    state: formData?.state,
                    zipCode: formData?.zipCode,
                    liquidNetWorth: formData?.liquidNetWorth,
                    annualIncome: formData?.annualIncome,
                    generalInvestmentObjective: formData?.generalInvestmentObjective,
                    riskLevel: formData?.riskLevel
                }
            }
        }
    })

    const HandleSave = async () => {
        try {
            kycUpsertMutation()
            setSnackbarOpen(true);
            handleClose();
        } catch (error) {
            console.error("Error updating KYC data:", error);
        }
    };

    const { loading, error, data } = useQueryAsUser(getKYC, { userKey: userKey });

    useEffect(() => {
        if (kycState !== 'NEED_KYC' && !loading) {
            setFormData(data?.kycGet?.kyc);
        }
    }, [data]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleChange = (field: string, value: string) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [field]: value
        }));
    };


    return (
        <>
            {kycState === 'MATCH' && (
                <Chip
                    label="MATCH"
                    variant="outlined"
                    color="success"
                    sx={{ marginLeft: '10px' }}
                />
            )}
            {kycState === 'NEED_KYC' && (
                <Chip
                    label="NEED KYC"
                    variant="outlined"
                    color="warning"
                    sx={{ marginLeft: '10px' }}
                />
            )}
            {kycState === 'MISMATCH' && (
                <Chip
                    label="MISMATCH"
                    variant="outlined"
                    color="error"
                    onClick={handleOpen}
                    icon={<ReportProblemIcon />}
                    sx={{ marginLeft: '10px', width: '30%' }}
                />
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Mismatch Information</DialogTitle>
                <DialogContent>
                    <p>There is a mismatch in the KYC data. Please review the details below.</p>
                    <TextField
                        label="First Name"
                        value={formData?.firstName || ''}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Last Name"
                        value={formData?.lastName || ''}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="DOB - Format: YYYY-MM-DD"
                        value={formData?.birthday || ''}
                        onChange={(e) => handleChange('birthday', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="address1"
                        value={formData?.address1 || ''}
                        onChange={(e) => handleChange('address1', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="address2"
                        value={formData?.address2 || ''}
                        onChange={(e) => handleChange('address2', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="city"
                        value={formData?.city || ''}
                        onChange={(e) => handleChange('city', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="state"
                        value={formData?.state || ''}
                        onChange={(e) => handleChange('state', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="zipCode"
                        value={formData?.zipCode || ''}
                        onChange={(e) => handleChange('zipCode', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <InputLabel id="demo-simple-select-label" sx={{fontSize: '12px'}}>liquidNetWorth</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.liquidNetWorth || ''}
                        onChange={(e) => handleChange('liquidNetWorth', e.target.value)}
                        fullWidth
                        margin="dense"
                     variant="outlined">
                        {Object.entries(LIQUID_NET_WORTH).map(([key, label]) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>

                    <InputLabel id="annualIncome-label" sx={{fontSize: '12px'}}>annualIncome</InputLabel>
                    <Select
                        labelId="annualIncome-label"
                        id="demo-simple-select"
                        value={formData?.annualIncome || ''}
                        onChange={(e) => handleChange('annualIncome', e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="outlined">
                        {Object.entries(ANNUAL_INCOME).map(([key, label]) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>

                    <InputLabel id="generalInvestmentObjective-label" sx={{fontSize: '12px'}}>generalInvestmentObjective</InputLabel>
                    <Select
                        labelId="generalInvestmentObjective-label"
                        id="demo-simple-select"
                        value={formData?.generalInvestmentObjective || ''}
                        onChange={(e) => handleChange('generalInvestmentObjective', e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="outlined">
                        {Object.entries(INVESTMENT_OBJECTIVE).map(([key, label]) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>

                    <InputLabel id="riskLevel-label" sx={{fontSize: '12px'}}>riskLevel</InputLabel>
                    <Select
                        labelId="riskLevel-label"
                        id="demo-simple-select"
                        value={formData?.riskLevel || ''}
                        onChange={(e) => handleChange('riskLevel', e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="outlined">
                        {Object.entries(RISK_LEVEL).map(([key, label]) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={() => HandleSave()} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    KYC data updated successfully!
                </Alert>
            </Snackbar>
        </>
    );
}
