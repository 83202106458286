import {gql} from "@apollo/client";

import { BROKER_NAME } from "../../../shared/portfolio-types";

export const getInstitutionDetails = gql`
    query whoisit($institutionKey: Int!){
        whoisit(
            input: {
                institutionKey: $institutionKey,
            }) {
                  institution {
                  institutionKey
                  userKey
                  createdAt
                  connectionStatus
                  institutionName
                  user{
                    firstName
                    lastName
                  }
                  kycCheck{
                    kycState
                  }
                  portfolios{
                    user{
                      firstName
                      lastName
                    }
                    portfolioName
                  }
            }
        }
    }`;

export const refreshAuthTokenMutation = gql`
    mutation ($institutionKey: Int!) {
        jediRefreshAuthToken(input:{institutionKey: $institutionKey}){
            success
        }
    }`;

export const disconnectInstitutionMutation = gql`
    mutation ($institutionKey: Int!, $userKey: Int!) {
          JediInstitutionDisconnect(input: {userKey: $userKey, institutionKey: $institutionKey}){
            success
        }
    }`;

export type InstitutionList = {
    instutions: InstitutionData[],
}
export type InstitutionData = {
    institutionKey: number,
    institutionName: BROKER_NAME,
    connectionStatus: string,
    portfolios: InstitutionPortfolio[]
}
export type InstitutionPortfolio = {
    portfolioKey: number,
    nickname: string,
    accountId: string,
}
export const listInstitutionsQuery = gql`
  query {
    institutionsGet(input: {connectionStatus: [CONNECTED, DISCONNECTED]}) {
      institutions {
        institutionKey
        institutionName
        connectionStatus
        portfolios {
          portfolioKey
          nickname
          accountId
        }
      }
    }
  }
`;