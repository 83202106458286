import React from "react";
import {Alert, Box, CircularProgress, Typography} from "@mui/material"
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import { LineChart } from '@mui/x-charts/LineChart';
import { jediPortfolioHistoryValueGet} from "../../clients/graphql/pages/users-queries";

export interface HistoricalValueChartProps{
    portfolioKey: number,
}

export default function UserHistoricalValueChart({ portfolioKey } : HistoricalValueChartProps){

    const {loading, error, data} = useQueryCoordinate(jediPortfolioHistoryValueGet, { variables: { portfolioKey: portfolioKey } });

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'jediStatusBreakdownQuery'</Alert>;
    }

    const valueData = data?.jediPortfolioHistoryValueGet?.historyValue ?? [];

    return(
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '35rem'}}>
                <Typography sx={{
                    color: '#6D6D6D',
                    font: 'Roboto',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '12px',
                    letterSpacing: '0.05em',
                    marginTop: '1.5rem',
                    marginLeft: '1rem',
                }} >
                    HISTORICAL VALUE
                </Typography><LineChart
                dataset={valueData}
                xAxis={[{
                    scaleType: 'band',
                    dataKey: 'timestamp',
                    valueFormatter: (value, context) => {
                        return `${value.slice(5, 7)}-${value.slice(8, 10)}-${value.slice(2, 4)}`
                    },
                }]}
                series={[
                    {
                        dataKey: 'buyingPower',
                        showMark: false,
                        valueFormatter: (value) => {
                            return 'Buying Power: ' + value?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        },
                        label: 'Buying Power',

                    },
                    {
                        dataKey: 'marketValue',
                        showMark: false,
                        valueFormatter: (value) => {
                            return 'Market Value: ' + value?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        },
                        label: 'Market Value',
                    },
                    {
                        dataKey: 'totalValue',
                        showMark: false,
                        valueFormatter: (value) => {
                            return 'Total Value: ' + value?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        },
                        label: 'Total Value',
                    },
                ]}
                bottomAxis={{
                    labelStyle: {
                        fontSize: 10,
                        transform: `translateY(${
                            5 * Math.abs(Math.sin((Math.PI * 45) / 180))
                        }px)`
                    },
                    tickLabelStyle: {
                        angle: 45,
                        textAnchor: 'start',
                        fontSize: 13,
                    },
                    disableTicks: true
                }}
                margin={{ left: 70, bottom: 120 }}
                sx={{height: '10rem'}}
            />

            </Box>

        </>
    )
}